import React, { useEffect, useState } from 'react';
import { authHeader } from '../helpers/auth-header';
import { setSessionVal } from '../helpers/general';
import { history } from '../helpers/history';
import ThemeSwitcher from './layout/ThemeSwitcher';

const Search = () => {

    const [tenderCounts, setTenderCounts] = useState({ active: 0, new: 0 });

    useEffect(() => {
        setSessionVal('TendersPageNumber', 1); // Reset the pageNumber 

        fetch('./Tenders/GetTenderCounts', {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => response.json())
            .then(data => {
                setTenderCounts({ active: data.active, new: data.new });
            })
            .catch(err => console.log('Err:', err));
    }, []);

    const [value, setValue] = useState({});

    const handleChange = e => {
        e.persist();

        setValue(value => ({
            ...value,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        }))
    }

    const handleSubmit = e => {
        e.preventDefault();

        if (value.search && value.search.length > 0) {
            history.push('./search-results', { search: value.search, showAll: value.showAll ?? false });
        }
    }

    return (
        <main className="u-flex-center">
            <div className="u-container u-container--small">
                <h1 className="u-branded-heading u-flex-center c-page-title c-page-title--home u-component-spacing--2x">Free tender contract portal</h1>
                <p className="u-component-spacing">Search through thousands of tender opportunities for a range of value contracts from In-tends' system portals and external sources.</p>
                <div className="c-search-and-stats u-component-spacing--2x">
                    <form className="c-search-form" onSubmit={handleSubmit}>
                        <div className="c-search-form__input-container">
                            <input className="c-search-form__input" placeholder=" " type="search" id="search-input" name="search" defaultValue={value.search} onChange={handleChange} />
                            <label className="c-search-form__label" htmlFor="search-input">Search for a product, service or organisation</label>
                        </div>
                        <div className="c-search-form__checkbox-container">
                            <input type="checkbox" id="include-all-tenders" name="showAll" className="u-visually-hidden c-search-form__checkbox" defaultValue={value.showAll} onChange={handleChange} />
                            <label htmlFor="include-all-tenders" className="c-search-form__checkbox-label">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="18" width="18">
                                    <path className="checkbox-icon__box" d="M1.607 24C.72 24 0 23.28 0 22.393V1.607C0 .72.72 0 1.607 0h20.786C23.28 0 24 .72 24 1.607v20.786C24 23.28 23.28 24 22.393 24H1.607ZM3.489 2.667a.818.818 0 0 0-.581.241.818.818 0 0 0-.241.581v17.022c0 .218.086.428.241.58a.818.818 0 0 0 .581.242h17.022a.818.818 0 0 0 .58-.241.818.818 0 0 0 .242-.581V3.489a.818.818 0 0 0-.241-.581.818.818 0 0 0-.581-.241H3.489Z" />
                                    <path className="checkbox-icon__check" fill="#34cb00" d="M10.121 18.12a.341.341 0 0 1-.483 0L3.92 12.4a.567.567 0 0 1 0-.804l1.316-1.316a.568.568 0 0 1 .804 0l3.598 3.599a.342.342 0 0 0 .483 0l7.838-7.84a.568.568 0 0 1 .803 0l1.318 1.317a.567.567 0 0 1 0 .804l-9.959 9.959Z" />
                                </svg>
                                Include concluded tenders
                            </label>
                        </div>
                        <input className="c-search-form__submit c-cta" type="submit" value="Search" />                       
                    </form>
                    <div className="c-stat-counter u-flex-center u-component-spacing--2x">
                        <p className="c-stat-counter__stat">
                            <strong className="c-stat-counter__stat-figure js-active-tenders" style={{ "--num": tenderCounts.active }}></strong>
                            <span className="c-stat-counter__label">active tenders</span>
                        </p>
                        <p className="c-stat-counter__stat">
                            <strong className="c-stat-counter__stat-figure js-new-tenders" style={{ "--num": tenderCounts.new }}></strong>
                            <span className="c-stat-counter__label">added today</span>
                        </p>
                    </div>
                </div>
                <div className="u-component-spacing--3x u-flex-center">
                    <h2 className="u-branded-underline">Contract sources</h2>
                </div>
                <ul className="c-tender-sources u-component-spacing--2x">
                    <li className="c-tender-sources__ojeu"><abbr title="Official Journal of the European Union">OJEU</abbr></li>
                    <li className="c-tender-sources__uk">
                        <ul className="u-list">
                            <li>UK Contracts Finder</li>
                            <li>UK Find a Tender</li>
                        </ul>
                    </li>
                    <li className="c-tender-sources__in-tend">
                        <svg viewBox="0 0 1785 546" xmlns="http://www.w3.org/2000/svg">
                            <use href="#in-tend-logo" />
                        </svg>
                    </li>
                </ul>
                <ThemeSwitcher />
            </div>
        </main>
    )
}

export default Search;